<template>
    <div class="cleaning-website">
      <!-- Header Section -->
      <header>
        <nav>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#testimonials">Testimonials</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </header>
  
      <!-- Home Section -->
      <section id="home" class="home-section">
        <div class="home-content">
          <h1>Welcome to XYZ Cleaning Services</h1>
          <p>We make your place spotless!</p>
          <a href="#contact" class="btn">Get a Quote</a>
        </div>
      </section>
  
      <!-- Services Section -->
      <section id="services" class="services-section">
        <h2>Our Services</h2>
        <div class="services-grid">
          <div class="service-item" v-for="(service, index) in services" :key="index">
            <img :src="service.img" :alt="service.name" />
            <h3>{{ service.name }}</h3>
            <p>{{ service.desc }}</p>
          </div>
          <!-- Add more service items as needed -->
        </div>
      </section>
  
      <!-- Testimonials Section -->
      <section id="testimonials" class="testimonials-section">
        <h2>Testimonials</h2>
        <div class="testimonial-slider">
          <div class="testimonial-item">
            <p>"The cleaning service provided by XYZ Cleaning Services is top-notch. Highly recommended!"</p>
            <span>- John Doe</span>
          </div>
          <div class="testimonial-item">
            <p>"I am amazed by the professionalism and efficiency of their cleaning team."</p>
            <span>- Jane Smith</span>
          </div>
          <!-- Add more testimonials as needed -->
        </div>
      </section>
  
      <!-- Contact Section -->
      <section id="contact" class="contact-section">
        <h2>Contact Us</h2>
        <form>
          <input type="text" placeholder="Your Name" />
          <input type="email" placeholder="Your Email" />
          <textarea placeholder="Your Message"></textarea>
          <button type="submit" class="btn">Submit</button>
        </form>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CleaningWebsite',
    data() {
    return {
      services: [
        {
            name:"Regular House Cleaning",
            desc: "Routine cleaning of all rooms, including dusting, vacuuming, mopping floors, and general tidying."
        },
        {
            name:"Deep Cleaning",
            desc: "Thorough cleaning that includes detailed cleaning of hard-to-reach areas, behind furniture, and inside appliances."
        },
        {
            name:"Carpet Cleaning",
            desc: "Steam cleaning or dry cleaning carpets and rugs to remove dirt, stains, and odors."
        },
        {
            name:"Office Cleaning",
            desc: "Regular cleaning services for offices, including workspaces, common areas, and restrooms."
        },
        {
            name:"Move-In/Move-Out Cleaning",
            desc: "Thorough cleaning of a property before new tenants move in or after old tenants move out."
        },
      ]
    };
  }
  };
  </script>
  
  <style>
  /* Reset some default styles */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

/* Set up some basic styling */
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #f9f9f9;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Header Styles */
header {
  background-color: #066490;
  padding: 20px 0;
}

header nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
}

header nav ul li {
  margin-right: 20px;
}

header nav ul li a {
  text-decoration: none;
  color: #fff;
}

/* Home Section Styles */
.home-section {
  text-align: center;
  padding: 100px 0;
  background-color: #fff;
}

.home-content h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: #333;
}

.home-content p {
  font-size: 18px;
  margin-bottom: 40px;
  color: #666;
}

/* Services Section Styles */
.services-section {
  background-color: #f9f9f9;
  padding: 100px 0;
}

.services-section h2 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 40px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.service-item {
  text-align: center;
}

.service-item img {
  max-width: 100%;
  border-radius: 5px;
}

.service-item h3 {
  font-size: 24px;
  margin: 20px 0;
}

.service-item p {
  font-size: 16px;
  color: #666;
}

/* Testimonials Section Styles */
.testimonials-section {
  text-align: center;
  padding: 100px 0;
}

.testimonials-section h2 {
  font-size: 30px;
  margin-bottom: 40px;
}

.testimonial-slider {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.testimonial-item {
  max-width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.testimonial-item p {
  font-style: italic;
  margin-bottom: 10px;
}

.testimonial-item span {
  font-weight: bold;
}

/* Contact Section Styles */
.contact-section {
  background-color: #f9f9f9;
  padding: 100px 0;
  text-align: center;
}

.contact-section h2 {
  font-size: 30px;
  margin-bottom: 40px;
}

.contact-section form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
}

.contact-section form input,
.contact-section form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}

.contact-section form button {
  padding: 10px 20px;
  background-color: #3a3a3a;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-section form button:hover {
  background-color: #555;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  header nav ul {
    flex-direction: column;
  }

  header nav ul li {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .services-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

  </style>
  