<template>
  <v-app>
    <v-main>
      <CleaningWebsite/>
      <!-- <PhotoAlbumWebsite/> -->
    </v-main>
  </v-app>
</template>

<script>
import CleaningWebsite from './components/CleaningWebsite.vue'
// import PhotoAlbumWebsite from './components/PhotoAlbumWebsite.vue'

export default {
  name: 'App',

  components: {
    CleaningWebsite,
    // PhotoAlbumWebsite,
  },

  data: () => ({
    //
  }),
}
</script>
